<template>
  <div>
    <a-alert class="info" type="info">
      <div slot="description">
        <div>
          <span class="label">用户姓名:</span>
          <span>{{ userInfo.userName }}</span>
        </div>
        <div>
          <span class="label">用户手机号:</span>
          <span>{{ userInfo.nickName }}</span>
        </div>
      </div>
    </a-alert>
     <a-table
      :columns="IntegralDetailColumns"
      :data-source="tabledData"
      :pagination="false"
      :loading="tableLoading"
      :row-key="record => record.createTime"
    >
      <span slot="actionType" slot-scope="text, record">{{ actionTypeMap.get(record.actionType) }}</span>
      <span slot="actions" slot-scope="text, record">
        <a v-if="operation.edit" @click="editClick(record)" style="margin-right: 10px">编辑</a>
        <a v-if="operation.frozen" style="margin-right: 10px">冻结/解冻</a>
        <router-link v-if="operation.check" :to="`/operateCenter/userIntegralList/detail/${record.id}`">查看变更</router-link>
      </span>
    </a-table>
    <MyPagination
      :count="total"
      :pageNo="pageNo"
      @showSizeChangeFn="paginationCallBack"
      v-show="!tableLoading"
    />
  </div>
</template>

<script>
import { creditChangeRecordAPI } from '@/request/api/operateCenter'
import { IntegralDetailColumns } from '../constants'
import MyPagination from '@/components/pagination/MyPagination'
const actionTypeMap = new Map([
  [1, '增加'],
  [2, '减少'],
  [3, '冻结'],
  [4, '解除冻结']
])
export default {
  components: { MyPagination },
  data() {
    return {
      actionTypeMap,
      total: 0,
      pageNo: 1,
      pageSize: 20,
      IntegralDetailColumns,
      tabledData: [],
      tableLoading: true,
      userInfo: {}
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      const params = {
        userNo: this.$route.params.userNo,
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }
      const { code, data } = await creditChangeRecordAPI(params)
      this.tableLoading = false
      if (code !== 200) return
      this.tabledData = data.list
      this.total = data.count
      this.userInfo = data.userInfo
    },
    paginationCallBack(current, pageSize) {
      this.pageNo = current
      this.pageSize = pageSize
      this.fetchData()
    },
  }
}
</script>

<style lang="less" scoped>
.info {
  font-weight: 500;
  .label {
    width: 100px;
    margin-right: 10px;
  }
}
</style>